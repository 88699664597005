<template>
  <!--打印进度 -->
  <div class="speed vw-100 vh-100 bgc-3">
    <se-return v-if="returnShow" :second="10"></se-return>
    <!-- 进度 -->
    <div class="fl-x-c sz-36 fw-bold f-color1 pgt-160 pgb-80">{{ state }}</div>
    <div class="fl-x-c h-180 speed-center">
      <el-progress
        v-if="(percent > 0) & (percent < 100)"
        type="circle"
        color="#24DADB"
        :percentage="percent"
        :width="360"
        :show-text="false"
      ></el-progress>
      <img v-else class="w-180 h-180" src="@/assets/img/勾@2x.png" alt="" />
    </div>
    <div
      v-if="returnShow"
      class="fl-x-c sz-24 fw-400 f-color1 mgt-30"
    >
      {{ stateText }}
    </div>
    <div v-else class="fl-x-c sz-24 fw-400 f-color1 mgt-30">
      {{ stateText }} {{ percent }}%
    </div>
    <se-footer></se-footer>
  </div>
</template>

<script>
import Footer from "@/components/footer";
import Return from "@/components/return";

export default {
  components: {
    "se-return": Return,
    "se-footer": Footer,
  },
  data() {
    return {
      percent: 0,
      timeOut: "",
      state: "打印中...",
      stateText: "打印进度",
      printShow: false, //判断是否打印
      returnShow: false,
    };
  },
  mounted() {
    this._setInter();
  },
  methods: {
    _setInter() {
      this.timeOut = setInterval(() => {
        if (this.percent < 100) {
          this.percent++;
        } else {
          // this.$router.push({ path: "/printfail" });//故障
          if (this.printShow) {
            clearInterval(this.timeOut);
            this.state = "数据已销毁";
            this.stateText = "请取走文件，欢迎再次使用";
            this.returnShow = true;
            return;
          }
          clearInterval(this.timeOut);
          this.state = "打印完毕";
          this.printShow = true;
          setTimeout(() => {
            this.destruction();
          }, 2000);
        }
      }, 100);
    },
    // 销毁数据
    destruction() {
      this.state = "数据销毁中...";
      this.stateText = "销毁进度";
      this.percent = 0;
      this._setInter();
    },
  },
  destroyed() {
    clearInterval(this.timeOut);
  },
};
</script>

<style lang="scss" scoped></style>
